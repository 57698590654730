import React from "react";
import { Box, Flex } from "@chakra-ui/react";

const AuthContainer = ({ children }) => {
  return (
    <Box
      backgroundImage={"url(/images/utility-bg-1080w.svg)"}
      height="90vh"
      style={{
        paddingTop: "180px",
        backgroundPositionX: "center",
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "-90px",
      }}
    >
      <Flex justifyContent="center">{children}</Flex>
    </Box>
  );
};

export default AuthContainer;
