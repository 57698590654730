import { Heading } from "@chakra-ui/react";
import { investorPortalRoutes, isClient } from "@verivest/shared-components";
import { logError } from "@verivest/shared-components/src/error-handling/logError";
import qs from "qs";
import React from "react";
import AuthContainer from "../../../components/container/AuthContainer";
import { IS_MANAGER } from "../../../utils/DynamicConfig";
import { getURLParams } from "../../../utils/utils";

const Login = () => {
  return (
    <AuthContainer>
      <Heading>Something went wrong</Heading>
    </AuthContainer>
  );
};

Login.getInitialProps = async ({ auth, dynamicConfig, ...rest }) => {
  let query = {};
  const isManager = dynamicConfig.get(IS_MANAGER);
  if (isClient()) {
    query = getURLParams(rest.location.search);
  } else {
    query = rest.req.query;
  }
  let redirectQuery = {};
  if (query.set_manager_id) {
    redirectQuery.set_manager_id = query.set_manager_id;
  }
  if (query.impersonating && !query.token) {
    const hasImpersonated = await auth.impersonateUser(query.impersonating);
    if (hasImpersonated) {
      return { redirectTo: "/?" + qs.stringify(redirectQuery) };
    }
  } else if (query.manager_impersonating && query.manager_id && !query.token) {
    const hasImpersonated = await auth.impersonateUserByManager(
      query.manager_impersonating,
    );
    if (hasImpersonated) {
      return { redirectTo: "/" };
    }
  }

  if (query.token) {
    try {
      await auth.loginThroughToken(query.token);

      if (query.back_to) {
        if (query.onboarding === "true") {
          const backTo =
            query.back_to === "/" ? investorPortalRoutes.home : query.back_to;

          let nUrl;

          try {
            nUrl = new URL(backTo);
          } catch (e) {
            nUrl = new URL(`${process.env.RAZZLE_APP_BASE_URL}${backTo}`);
          }

          const qString = nUrl.searchParams.toString();
          return {
            redirectTo: `${nUrl.origin}${nUrl.pathname}?${
              qString ? `${qString}&` : ""
            }&ask_mfa_setup=1`,
          };
        }

        return { redirectTo: query.back_to };
      }

      if (query.impersonating) {
        redirectQuery.impersonating = query.impersonating;
        return {
          redirectTo: "/login?" + qs.stringify(redirectQuery),
        };
      }
      let redirectTo = dynamicConfig.get(IS_MANAGER)
        ? "/"
        : investorPortalRoutes.home;
      const redirectUrl = auth.cookies.get("redirect_login");
      if (redirectUrl && redirectUrl !== "") {
        redirectTo = redirectUrl;
        auth.cookies.delete("redirect_login");
      }

      if (query.onboarding) {
        if (isManager) {
          redirectTo = "/w/onboarding";
        } else {
          redirectTo = investorPortalRoutes.home;
          redirectQuery.ask_mfa_setup = "1";
        }
      }
      return { redirectTo: redirectTo + "?" + qs.stringify(redirectQuery) };
    } catch (error) {
      logError("error loading user from token", error);
      return { er: error };
    }
  }
  return {};
};
export default Login;
